<template>
  <div class="organ-management-page" v-loading="loading">
    <el-card shadow="always" class="card" style="margin-top: 0">
      <div style="display: flex; justify-content: space-between; background-color: #fff; align-items: center">
        <h4 style="margin: 0; line-height: 32px">欢迎：{{ userpower?userpower.userInfo.nickname:"" }}</h4>
        <div>
          <el-button type="primary" @click="addNewCustomer"><i class="el-icon-plus"></i> 新增客户</el-button>
        </div>
      </div>
    </el-card>
    <el-card shadow="always" class="card" style="margin: 20px 0 -5px 0">
      <el-row class="organ-management-nav">
        <div @click="orderclick" class="navchlid">
          <div class="imgbox2" style="background-color: #ecf5ff">
            <div class="imgbox1" style="background-color: #c0deff">
              <img src="https://dev-uat-alanber.oss-cn-chengdu.aliyuncs.com/2021092310104279373752.png" alt="" />
            </div>
          </div>
          <div style="text-align: center">
            <span>{{ tableData.taskNum }}</span>
            <p>今日预约</p>
          </div>
        </div>
        <div @click="arriveclick" class="navchlid">
          <div class="imgbox2" style="background-color: #fff8e7">
            <div class="imgbox1" style="background-color: #ffe5b3">
              <img src="https://dev-uat-alanber.oss-cn-chengdu.aliyuncs.com/2021092310142754551323.png" alt="" />
            </div>
          </div>
          <div style="text-align: center">
            <span>{{ tableData.arriveNum }}</span>
            <p>今日到访</p>
          </div>
        </div>
        <div @click="visitclick" class="navchlid">
          <div class="imgbox2" style="background-color: #e0fdf3">
            <div class="imgbox1" style="background-color: #97fad9">
              <img src="https://dev-uat-alanber.oss-cn-chengdu.aliyuncs.com/2021092310163375695214.png" alt="" />
            </div>
          </div>
          <div style="text-align: center">
            <span>{{ tableData.doorNum }}</span>
            <p>今日上门</p>
          </div>
        </div>
        <div @click="mailclick" class="navchlid">
          <div class="imgbox2" style="background-color: #ffedeb">
            <div class="imgbox1" style="background-color: #ffc8c3">
              <img src="https://dev-uat-alanber.oss-cn-chengdu.aliyuncs.com/2021092310165788427736.png" alt="" />
            </div>
          </div>
          <div style="text-align: center">
            <span>{{ tableData.maillingNum }}</span>
            <p>今日邮寄</p>
          </div>
        </div>
        <div @click="newaddclick" class="navchlid" style="border-right: 0">
          <div class="imgbox2" style="background-color: #fbf1ff">
            <div class="imgbox1" style="background-color: #f0d2ff">
              <img src="https://dev-uat-alanber.oss-cn-chengdu.aliyuncs.com/2021092311134742464400.png" alt="" />
            </div>
          </div>
          <div style="text-align: center">
            <span>{{ tableData.orderNums }}</span>
            <p>今日新增</p>
          </div>
        </div>
      </el-row>
    </el-card>
    <el-card shadow="always" class="card">
      <p style="margin: -4px 0 10px">待办任务</p>
      <div class="table-block">
        <el-table
          :data="tableDatalist"
          :header-cell-style="{ 'text-align': 'center', background: '#EEF1FC', color: '#999' }"
          :cell-style="{ 'text-align': 'center' }"
          height="385"
          style="width: 100%"
          @selection-change="handleSelectionChange"
        >
          <!-- <el-table-column type="selection" width="50"></el-table-column> -->
          <!-- <el-table-column type="index" label="序号"> </el-table-column> -->
          <el-table-column prop="customName" label="客户姓名"> </el-table-column>
          <el-table-column prop="subStatusName" label="任务状态"> </el-table-column>
          <el-table-column prop="tel1" label="手机号"> </el-table-column>
          <el-table-column prop="tasktypeName" label="任务类型"> </el-table-column>
          <el-table-column prop="samplingTime" label="时间"> </el-table-column>
          <el-table-column prop="samplingTypeOn" label="来源"> </el-table-column>
          <el-table-column fixed="right" label="操作" width="100">
            <template slot-scope="scope">
              <el-button @click="handleClick(scope.row)" type="text" size="small">详情</el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <!-- 上门任务详情 -->
      <theDoorDialog :info="info" @closeTheDoor="closeTheDoor" v-if="showtheDoor" :dates="addNewdata"></theDoorDialog>
      <!-- 邮寄任务详情 -->
      <mailTaskDialog :info="info" @closeMailTask="closeMailTask" v-if="showMailDialog"></mailTaskDialog>
      <!-- 到访任务详情 -->
      <visitDialog :info="info" @closeVisit="closeVisit" v-if="showvisit"></visitDialog>

      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="currentPage"
        :page-sizes="[7, 14, 21, 28]"
        :page-size="pagesize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
        background
      >
      </el-pagination>
    </el-card>
    <addUsers v-if="showAddUser" @closeAdd="closeAdd" :info="info" :title="title"></addUsers>
  </div>
</template>

<script>
import addUsers from '@/views/customerManagement/component/addUsers'
import theDoorDialog from '@/views/appointmentManagement/components/theDoorDialog'
import mailTaskDialog from '@/views/appointmentManagement/components/mailTaskDialog'
import visitDialog from '@/views/appointmentManagement/components/visitDialog'
import { postRequestAnthor } from '@/api/index'
export default {
  data() {
    return {
      showtheDoor: false,
      showMailDialog: false,
      showvisit: false,
      tableData: '',
      tableDatalist: [],
      multipleSelection: [],
      total: 0,
      pagesize: 7,
      showAddUser: false,
      currentPage: 1,
      title: '',
      userpower: '',
      loading:false
    }
  },
  created() {
    this.loading = true
    this.userpower = JSON.parse(localStorage.getItem('newToken'))
    this.loaddata(this.pagesize, this.currentPage, 1)
    // console.log(this.userpower);
  },
  components: { addUsers, theDoorDialog, mailTaskDialog, visitDialog },
  methods: {
    // 获取页面数据
    loaddata(pageSize, pageNo, isAllData) {
      postRequestAnthor('/workbench/workbenchdata', { pageSize, pageNo, isAllData }).then(res => {
        this.loading = false
        this.tableData = res
        // console.log(this.tableData);
        this.tableDatalist = res.taskData.data
        this.total = res.taskData.count
      }).catch(()=>{
        this.loading = false
      })
    },
    // 预约任务
    orderclick() {
      if (
        this.userpower.resources.some(item => {
          return item.webId == 23
        })
      ) {
        this.$router.push('appointmentRegister')
      } else {
        this.$message({
          message: '暂无预约任务权限',
          type: 'warning',
        })
      }
    },
    // 到访任务
    arriveclick() {
      if (
        this.userpower.resources.some(item => {
          return item.webId == 39
        })
      ) {
        this.$router.push('visit')
      } else {
        this.$message({
          message: '暂无到访任务权限',
          type: 'warning',
        })
      }
    },
    // 上门任务
    visitclick() {
      if (
        this.userpower.resources.some(item => {
          return item.webId == 37
        })
      ) {
        this.$router.push('theDoor')
      } else {
        this.$message({
          message: '暂无上门任务权限',
          type: 'warning',
        })
      }
    },
    // 邮寄任务
    mailclick() {
      if (
        this.userpower.resources.some(item => {
          return item.webId == 38
        })
      ) {
        this.$router.push('mailTask')
      } else {
        this.$message({
          message: '暂无邮寄任务权限',
          type: 'warning',
        })
      }
    },
    // 新增订单
    newaddclick() {
      if (
        this.userpower.resources.some(item => {
          return item.webId == 29
        })
      ) {
        this.$router.push('orderManagement')
      } else {
        this.$message({
          message: '暂无新增订单权限',
          type: 'warning',
        })
      }
    },
    // 新增客户
    addNewCustomer() {
      if (
        this.userpower.resources.some(item => {
          return item.webId == 2
        })
      ) {
        this.showAddUser = true
        this.info = ''
      } else {
        this.$message({
          message: '暂无新增客户权限',
          type: 'warning',
        })
      }
    },
    closeAdd() {
      this.showAddUser = false
      // this.getAllCustomers()
    },
    closeTheDoor() {
      console.log('上门')
      this.showtheDoor = false
    },
    closeMailTask() {
      console.log('邮寄')
      this.showMailDialog = false
    },
    closeVisit() {
      console.log('到访')
      this.showvisit = false
    },
    handleSelectionChange(val) {
      this.multipleSelection = val
    },
    handleClick(item) {
      this.info = item
      console.log(item.samplingType)
      if (item.samplingType == 1) {
        if (
          this.userpower.resources.some(item => {
            return item.webId == 37
          })
        ) {
          console.log('上门任务')
          this.showtheDoor = true
        } else {
          this.$message({
            message: '暂无上门任务权限',
            type: 'warning',
          })
        }
      } else if (item.samplingType == 2 || item.samplingType == 4) {
        console.log(this.userpower.resources)
        if (
          this.userpower.resources.some(item => {
            console.log(item.webId)
            return item.webId == 39
          })
        ) {
          console.log('到访任务')
          this.showvisit = true
        } else {
          this.$message({
            message: '暂无到访任务权限',
            type: 'warning',
          })
        }
      } else if (item.samplingType == 3) {
        if (
          this.userpower.resources.some(item => {
            return item.webId == 38
          })
        ) {
          console.log('邮寄任务')
          this.showMailDialog = true
        } else {
          this.$message({
            message: '暂无邮寄任务权限',
            type: 'warning',
          })
        }
      }
    },
    handleSizeChange(val) {
      this.loading = true
      this.pagesize = val
      this.loaddata(this.pagesize, this.currentPage, 2)
    },
    handleCurrentChange(val) {
      this.loading = true
      this.currentPage = val
      this.loaddata(this.pagesize, this.currentPage, 2)
    },
  },
}
</script>

<style lang="scss" scoped>
.organ-management-nav {
  display: flex;
  background-color: #fff;
  div {
    img {
      width: 20px;
      height: 20px;
    }
    span {
      font-size: 24px;
      font-family: PingFang SC;
      font-weight: 500;
      color: #121518;
    }
    p {
      font-size: 16px;
      font-family: PingFang SC;
      font-weight: 400;
      color: #121518;
      text-align: center;
      margin:8px  0 ;
    }
  }
  .navchlid {
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1;
    cursor: pointer;
    border-right: 2px solid #eee;
  }
  .imgbox1 {
    padding: 8px 8px 4px;
    border-radius: 50%;
  }
  .imgbox2 {
    padding: 10px;
    border-radius: 50%;
    margin-right: 20px;
  }
}
.search {
  display: flex;
  justify-content: space-between;
}
b {
  display: inline-block;
  // width: 10px;
  // height: 10px;
  text-align: center;
  line-height: 10px;
  background: #ffcc66;
  padding: 5px;
  border-radius: 50%;
  color: #fff;
}
.el-card__body {
  padding: 12px !important;
}
.organ-management-page {
  background: transparent;
}
.line-top {
  width: 100%;
  height: 50px;
  background: #fff;
}
</style>